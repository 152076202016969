// ** React Imports
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Slide, toast } from "react-toastify";
import Flatpickr from "react-flatpickr";
import moment from "moment";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import "@styles/react/libs/tables/react-dataTable-component.scss";
import "@styles/react/libs/flatpickr/flatpickr.scss";
import SpinnerFlex from "views/components/spinners/SpinnerFlex";
import {
  createSubscription,
  createTopup,
  getKyc,
  getPlans,
  createDailyTalktimeTopup,
  getAllDailyTalktimes,
} from "../store/action/index";
import { Wrapper } from "../../../../components/wrapper/wrapper.component";
import { toggleBusinessKycBypass } from "../../kyc/store/action";
import { selectSelectedTeam } from "../store/teams.selector";
import {
  useAddOrgNoteMutation,
  useGetAllPlansQuery,
  useUpdateOrgMutation,
} from "../../../../redux/api/superfone-api";
import styles from "./style.module.scss";
import { setOrgEmail } from "../../../../redux/slices/notes/notes.api";
import { legacy_plan_ids, new_plan_ids } from "../daily-talktime/new-plan-ids";
const MySwal = withReactContent(Swal);
export const CREATE_DAILY_TALKTIME_NAMES = {
  DAILY_TALKTIME_CHOTA_TOP_UP: "Chota top-up",
  DAILY_TALKTIME_RECURRING_TOP_UP: "Recurring top-up",
};

export const CREATE_DAILY_TALKTIME_TYPES = {
  DAILY_TALKTIME_CHOTA_TOP_UP: "DAILY_TALKTIME_CHOTA_TOP_UP",
  DAILY_TALKTIME_RECURRING_TOP_UP: "DAILY_TALKTIME_RECURRING_TOP_UP",
};

const CreateSubModal = ({
  onDismiss = () => {},
  onClose = () => {},
  onProceed = () => {},
  visible = false,
  team,
  currentSubscription,
}) => {
  const { data: plans } = useGetAllPlansQuery();
  const loaders = useSelector((state) => state.teams.loaders);
  const [paymentId, setPaymentId] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const chosenPlan = plans?.find(
    (p) => p.id === Number(selectedPlan?.value || 0)
  );
  const [selectedVariantId, setSelectedVariantId] = useState(0);
  const [paymentType, setPaymentType] = useState({
    label: "Razorpay",
    value: "RAZORPAY",
  });

  const [topupType, setTopupType] = useState({
    label: "Chota topup (expires eod)",
    value: CREATE_DAILY_TALKTIME_TYPES.DAILY_TALKTIME_CHOTA_TOP_UP,
  });

  const variant = chosenPlan?.variants?.find((v) => v.id === selectedVariantId);
  const org = useSelector(selectSelectedTeam);
  const [amount, setAmount] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [orderType, setOrderType] = useState("PURCHASE");
  const [selectedAddons, setSelectedAddons] = useState([]);
  const { addons = [] } = chosenPlan || {};
  const [active, setActive] = useState("SUB");
  const [talktime, setTalkTime] = useState(500);
  const handlePaymentIdChange = (e) => {
    setPaymentId(e.target.value);
  };

  const clearData = () => {
    setSelectedPlan(null);
    setSelectedVariantId(0);
    setPaymentId("");
    // setIsChecked(true);
    setStartDate(null);
    setEndDate(null);
    setSelectedAddons([]);
    setExtraFields({
      gst: "",
      description: "",
    });
    setTalkTime(500);
    setAmount("");
  };

  const handleOnClose = () => {
    onClose();
    clearData();
  };

  const handleOnDismiss = () => {
    onDismiss();
    clearData();
  };

  const [extraFields, setExtraFields] = useState({
    gst: "",
    description: "",
  });
  useEffect(() => {
    if (org.gstin) {
      setExtraFields((extra) => ({
        ...extra,
        gst: org.gstin,
      }));
    }
  }, [org.gstin]);

  const onSubmit = () => {
    if (active === "TOUPUPV2") {
      onProceed({
        payment_id: paymentNotCollected ? null : paymentId.trim(),
        plan: chosenPlan,
        ...(paymentType?.value ? { payment_type: paymentType.value } : {}),
        ...(amount ? { amount: paymentNotCollected ? 0 : Number(amount) } : {}),
        variant,
        start_date: startDate
          ? moment(startDate).startOf("day").toDate()
          : moment().startOf("day").toDate(),
        order_type: orderType,
        addons: selectedAddons,
        talktime: talktime,
        type: active,
        ...(extraFields?.gst ? { gstin: extraFields.gst } : {}),
        ...(extraFields?.description
          ? { description: extraFields.description }
          : {}),
        topup_type: topupType.value,
        end_date: endDate
          ? moment(endDate).endOf("day").toDate()
          : moment().endOf("day").toDate(),
      });
    } else {
      onProceed({
        payment_id: paymentNotCollected ? null : paymentId.trim(),
        plan: chosenPlan,
        ...(paymentType?.value ? { payment_type: paymentType.value } : {}),
        ...(amount ? { amount: paymentNotCollected ? 0 : Number(amount) } : {}),
        variant,
        start_date: startDate
          ? moment(startDate).startOf("day").toDate()
          : null,
        order_type: orderType,
        addons: selectedAddons,
        talktime: talktime,
        type: active,
        ...(extraFields?.gst ? { gstin: extraFields.gst } : {}),
        ...(extraFields?.description
          ? { description: extraFields.description }
          : {}),
      });
    }
    clearData();
  };

  const handleOnProceed = () => {
    if (
      currentSubscription?.plan?.name?.toLowerCase()?.includes("standard") &&
      chosenPlan?.slug?.toLowerCase()?.includes("free-tier")
    ) {
      MySwal.fire({
        title: "Cannot give CRM/WABA with standard phone plan",
        text: "",
      });
      return;
    }
    if (!extraFields?.gst) {
      MySwal.fire({
        icon: "warning",
        title: "No GST",
        text: `No GST invoice will be given if no GST is entered. Sure to go ahead?`,
        confirmButtonText: "Go ahead without GST",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-warning",
          cancelButton: "btn btn-secondary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          onSubmit();
        }
      });
    } else {
      onSubmit();
    }
  };

  const onChangePlan = (selection) => {
    setSelectedPlan(selection);
    const chosenPlan = plans?.find((p) => {
      return p.id === Number(selection.value);
    });
    setSelectedVariantId(chosenPlan?.variants?.[0]?.id || 0);
  };

  const setPicker = (date) => {
    setStartDate(date[0]);
  };

  const setPickerEndDate = (date) => {
    setEndDate(date[0]);
  };

  const noOfMonths = variant?.duration ? Math.floor(variant.duration / 30) : 0;
  const addonsPrice =
    selectedAddons.reduce((sum, id) => {
      const addOn = addons.find((a) => a.id === id);
      return addOn.amount / 100 + sum;
    }, 0) * noOfMonths;

  const oldPlans = plans.filter((p) => !new_plan_ids.includes(p.id));
  const legacyPlans = oldPlans.filter((p) => legacy_plan_ids.includes(p.id));
  const restLegacyPlans = oldPlans.filter(
    (p) => !legacy_plan_ids.includes(p.id)
  );

  const [paymentNotCollected, setPaymentNotCollected] = useState(false);

  return (
    <div className="demo-inline-spacing">
      <div>
        <Modal scrollable isOpen={visible} toggle={handleOnClose}>
          <ModalHeader toggle={handleOnClose}>
            Create Plan for team id: {team.id} name: ({team.name})
          </ModalHeader>
          {loaders.plans ? (
            <SpinnerFlex />
          ) : (
            <>
              <ModalBody style={{ height: "800px" }}>
                <Nav tabs style={{ display: "flex", justifyContent: "center" }}>
                  <NavItem>
                    <NavLink
                      active={active === "SUB"}
                      onClick={() => {
                        clearData();
                        setActive("SUB");
                      }}
                    >
                      Create Plan
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={active === "TOUPUP"}
                      onClick={() => {
                        clearData();
                        setActive("TOUPUP");
                      }}
                    >
                      Add Pulse
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={active === "TOUPUPV2"}
                      onClick={() => {
                        clearData();
                        setActive("TOUPUPV2");
                      }}
                    >
                      Add Daily Pulse Minutes
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent className="py-50" activeTab={active}>
                  <TabPane tabId="SUB">
                    <>
                      <Form
                        className="auth-register-form mt-2"
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <FormGroup className="mb-1">
                          <div className="d-flex align-items-center user-total-numbers">
                            <div className="d-flex align-items-center mr-2">
                              <div>
                                <p className="mb-0">Order Type:</p>
                              </div>
                              {/* <div className="ml-1 color-box">
                          <CheckSquare className="text-success" />
                        </div>
                        <div className="ml-1">
                          <h5 className="mb-0">PURCHASE</h5>
                        </div> */}
                              <div className="ml-1 color-box">
                                <CustomInput
                                  type="checkbox"
                                  className="custom-control-Primary"
                                  id="purchase-checkbox"
                                  label="PURCHASE"
                                  onChange={(e) => setOrderType("PURCHASE")}
                                  checked={orderType === "PURCHASE"}
                                />
                              </div>
                              <div className="ml-1 color-box">
                                <CustomInput
                                  type="checkbox"
                                  className="custom-control-Primary"
                                  id="renewal-checkbox"
                                  label="RENEWAL"
                                  onChange={(e) => setOrderType("RENEW")}
                                  checked={orderType === "RENEW"}
                                />
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                        <Wrapper padding="1rem 0" fullWidth type="vertical">
                          <div className={styles["custom-select"]}>
                            <Select
                              theme={selectThemeColors}
                              isClearable={false}
                              className="react-select"
                              classNamePrefix="select"
                              options={[
                                {
                                  label: "Plans",
                                  options: [
                                    ...plans
                                      .filter((plan) =>
                                        new_plan_ids.includes(Number(plan.id))
                                      )
                                      .map((p) => ({
                                        value: p.id,
                                        label: p.description,
                                      })),
                                  ].sort(
                                    (a, b) =>
                                      new_plan_ids.indexOf(a.value) -
                                      new_plan_ids.indexOf(b.value)
                                  ),
                                },
                                {
                                  label: "Legacy Plans",
                                  options: [
                                    ...[
                                      ...legacyPlans.map((p) => ({
                                        value: p.id,
                                        label: p.description,
                                      })),
                                    ].sort(
                                      (a, b) =>
                                        legacy_plan_ids.indexOf(a.value) -
                                        legacy_plan_ids.indexOf(b.value)
                                    ),
                                    ...restLegacyPlans.map((p) => ({
                                      value: p.id,
                                      label: p.description,
                                    })),
                                  ],
                                },
                              ]}
                              value={selectedPlan}
                              placeholder="Choose Subscription Plan"
                              onChange={onChangePlan}
                            />
                          </div>
                        </Wrapper>
                        {selectedPlan ? (
                          <div className="tw-flex tw-gap-4 tw-items-center tw-pb-4 tw-flex-wrap">
                            {chosenPlan?.variants?.map((v) => {
                              return (
                                <div
                                  key={v.id}
                                  className="tw-cursor-pointer tw-border tw-border-solid tw-p-2 tw-rounded-lg tw-min-w-[33%] tw-border-black/30 tw-flex tw-gap-2 tw-items-start"
                                  onClick={() => {
                                    setSelectedVariantId(v.id);
                                  }}
                                >
                                  <CustomInput
                                    id={v.id}
                                    type="radio"
                                    checked={selectedVariantId === v.id}
                                  />
                                  <div className="tw-flex tw-flex-col tw-gap-1 tw-items-start">
                                    <div className="tw-font-semibold">
                                      {v.name}
                                    </div>
                                    <div>{`Rs. ${v.amount / 100}`}</div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                        <div
                          className="tw-py-4 tw-cursor-pointer"
                          onClick={() => {
                            setPaymentNotCollected(!paymentNotCollected);
                            if (!paymentNotCollected) {
                              setAmount("");
                              setPaymentId("");
                            }
                          }}
                        >
                          <CustomInput
                            id="paymentNotCollected"
                            type="checkbox"
                            checked={paymentNotCollected}
                            className="tw-pointer-events-none"
                          >
                            <strong className="tw-select-none">
                              Payment not collected
                            </strong>
                          </CustomInput>
                        </div>
                        <div
                          className={`tw-pb-4 ${
                            paymentNotCollected
                              ? "tw-opacity-50 tw-grayscale tw-pointer-events-none"
                              : ""
                          }`}
                        >
                          <Select
                            theme={selectThemeColors}
                            isClearable={false}
                            className="react-select"
                            classNamePrefix="select"
                            options={[
                              {
                                label: "Razorpay",
                                value: "RAZORPAY",
                              },
                              {
                                label: "Bank Transfer",
                                value: "BANK_TRANSFER",
                              },
                            ]}
                            value={paymentType}
                            placeholder="Choose Payment Type"
                            onChange={(paymentType) => {
                              setPaymentType(paymentType);
                            }}
                          />
                        </div>
                        {paymentType?.value === "BANK_TRANSFER" ? (
                          <div
                            className={`tw-pb-4 ${
                              paymentNotCollected
                                ? "tw-opacity-50 tw-grayscale tw-pointer-events-none"
                                : ""
                            }`}
                          >
                            <Input
                              autoFocus
                              type="number"
                              value={amount}
                              placeholder="Enter rupees"
                              id="p-amount-id"
                              name="p-amount-name"
                              onChange={(e) => {
                                setAmount(e?.target?.value || "");
                              }}
                            />
                          </div>
                        ) : null}

                        <FormGroup
                          className={`tw-pb-4 ${
                            paymentNotCollected
                              ? "tw-opacity-50 tw-grayscale tw-pointer-events-none"
                              : ""
                          }`}
                        >
                          <Input
                            autoFocus
                            type="text"
                            value={paymentId}
                            placeholder="Enter Payment Id"
                            id="p-id"
                            name="p-id"
                            onChange={handlePaymentIdChange}
                          />
                          <Label className="form-label" for="p-id">
                            Enter Payment Id (Razorpay/Bank Transfer)
                            <div>
                              <small>
                                if amount paid is equal to subscription plan
                                amount
                              </small>
                            </div>
                          </Label>
                        </FormGroup>

                        <FormGroup>
                          <Label className="form-label" for="p-id">
                            Select Plan Start date (Optional)
                          </Label>
                          <Flatpickr
                            value={startDate || new Date()}
                            onChange={(date) => setPicker(date)}
                            className="form-control invoice-edit-input date-picker"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label className="form-label">GST</Label>
                          <Input
                            autoFocus
                            type="text"
                            {...(org.gstin ? { readOnly: org.gstin } : {})}
                            value={extraFields.gst || org.gstin}
                            placeholder="Enter GST"
                            id="p-cs-gst-id"
                            name="p-cs-gst-name"
                            onChange={(e) => {
                              setExtraFields({
                                ...extraFields,
                                gst: e?.target?.value || "",
                              });
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label className="form-label">Description</Label>
                          <Input
                            type="textarea"
                            value={extraFields.description || ""}
                            placeholder="Enter Detailed Description"
                            id="p-cs-description-id"
                            name="p-cs-description-name"
                            onChange={(e) => {
                              setExtraFields({
                                ...extraFields,
                                description: e?.target?.value || "",
                              });
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <div className="tw-text-[14px] tw-leading-[18px] tw-font-semibold">
                            Total Plan amount: Rs.{" "}
                            {variant?.amount
                              ? (variant.amount / 100).toFixed(2)
                              : 0}
                          </div>
                          {Array.isArray(selectedAddons) &&
                          selectedAddons.length ? (
                            <div className="tw-text-[14px] tw-leading-[18px] tw-font-semibold">
                              Total Addons amount: Rs.{" "}
                              {Array.isArray(selectedAddons) &&
                              selectedAddons.length
                                ? addonsPrice.toFixed(2)
                                : 0}
                            </div>
                          ) : null}
                          <div
                            style={{
                              fontSize: "14px",
                              lineHeight: "18px",
                              fontWeight: "600",
                            }}
                          >
                            Total Payable Amount : Rs.{" "}
                            {(
                              (variant?.amount || 0) / 100 +
                              addonsPrice
                            ).toFixed(2)}
                          </div>
                        </FormGroup>
                        {Array.isArray(addons) && addons.length ? (
                          <>
                            <FormGroup>
                              <Label className="form-label" for="p-id">
                                Addons
                              </Label>
                              <ul
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {addons.map((addon) => {
                                  const { id } = addon;
                                  const checked = !!selectedAddons.find(
                                    (e) => e === id
                                  );
                                  return (
                                    <li
                                      style={{
                                        fontSize: "16px",
                                        textAlign: "left",
                                        listStyle: "none",
                                      }}
                                      key={id}
                                    >
                                      <Input
                                        type="checkbox"
                                        checked={checked}
                                        onChange={(e) => {
                                          const checked =
                                            e?.target?.checked || false;
                                          if (checked) {
                                            setSelectedAddons([
                                              ...selectedAddons,
                                              id,
                                            ]);
                                          } else {
                                            setSelectedAddons(
                                              selectedAddons.filter(
                                                (key) => key !== id
                                              )
                                            );
                                          }
                                        }}
                                        id="basic-cb-checked"
                                      />
                                      <span
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {addon.name}{" "}
                                        <span
                                          style={
                                            checked ? { fontWeight: "600" } : {}
                                          }
                                        >
                                          Rs. {addon.amount / 100}
                                        </span>
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </FormGroup>
                          </>
                        ) : null}
                      </Form>
                    </>
                  </TabPane>
                </TabContent>
                <TabContent className="py-50" activeTab={active}>
                  <TabPane tabId="TOUPUP">
                    <>
                      <Form
                        className="auth-register-form mt-2"
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <FormGroup>
                          <Label className="form-label" for="p-id">
                            Incoming Pulse Minutes to be Added
                          </Label>
                          <Input
                            autoFocus
                            type="number"
                            value={talktime}
                            placeholder="1"
                            min={500}
                            max={10000}
                            step={500}
                            id="p-id"
                            name="p-id"
                            onChange={(e) => {
                              let value = e?.target?.value;
                              value = Math.ceil(value / 500) * 500;
                              setTalkTime(value);
                            }}
                          />
                        </FormGroup>
                        <div className="tw-pb-4">
                          <Select
                            theme={selectThemeColors}
                            isClearable={false}
                            className="react-select"
                            classNamePrefix="select"
                            options={[
                              {
                                label: "Razorpay",
                                value: "RAZORPAY",
                              },
                              {
                                label: "Bank Transfer",
                                value: "BANK_TRANSFER",
                              },
                            ]}
                            value={paymentType}
                            placeholder="Choose Payment Type"
                            onChange={(paymentType) => {
                              setPaymentType(paymentType);
                            }}
                          />
                        </div>
                        {paymentType?.value === "BANK_TRANSFER" ? (
                          <div className="tw-pb-4">
                            <Input
                              autoFocus
                              type="number"
                              value={amount}
                              placeholder="Enter rupees"
                              id="p-amount-id"
                              name="p-amount-name"
                              onChange={(e) => {
                                setAmount(e?.target?.value || "");
                              }}
                            />
                          </div>
                        ) : null}
                        <div
                          style={{
                            fontSize: "14px",
                            lineHeight: "18px",
                            fontWeight: "600",
                            marginBottom: "1rem",
                          }}
                        >
                          Price :
                          {paymentType?.value === "BANK_TRANSFER" ? (
                            <span> Rs. {amount}</span>
                          ) : (
                            <span> Rs. {talktime * 0.708}</span>
                          )}
                        </div>
                        {paymentType?.value === "BANK_TRANSFER" ? (
                          <div
                            style={{
                              fontSize: "14px",
                              lineHeight: "18px",
                              marginBottom: "1rem",
                              color: "rgba(0,0,0,0.7)",
                            }}
                          >
                            Estimated Price :
                            <span> Rs. {talktime * 0.708}</span>
                          </div>
                        ) : null}

                        <FormGroup>
                          <Input
                            autoFocus
                            type="text"
                            value={paymentId}
                            placeholder="Enter Payment Id"
                            id="p-id"
                            name="p-id"
                            onChange={handlePaymentIdChange}
                          />
                          <Label className="form-label" for="p-id">
                            Enter Payment Id (Razorpay/Bank Transfer)
                            <div>
                              <small>
                                if amount paid is equal to subscription plan
                                amount
                              </small>
                            </div>
                          </Label>
                        </FormGroup>
                        <FormGroup>
                          <Label className="form-label">GST</Label>
                          <Input
                            value={extraFields.gst || ""}
                            placeholder="Enter GST"
                            id="p-topup-gst-id"
                            name="p-topup-gst-name"
                            onChange={(e) => {
                              setExtraFields({
                                ...extraFields,
                                gst: e?.target?.value || "",
                              });
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label className="form-label">Description</Label>
                          <Input
                            type="textarea"
                            value={extraFields.description}
                            placeholder="Enter Detailed Description"
                            id="p-topup-description-id"
                            name="p-topup-description-name"
                            onChange={(e) => {
                              setExtraFields({
                                ...extraFields,
                                description: e?.target?.value || "",
                              });
                            }}
                          />
                        </FormGroup>
                      </Form>
                    </>
                  </TabPane>
                </TabContent>
                <TabContent className="py-50" activeTab={active}>
                  <TabPane tabId="TOUPUPV2">
                    <>
                      <Form
                        className="auth-register-form mt-2"
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <div className="tw-pb-4">
                          <Select
                            theme={selectThemeColors}
                            isClearable={false}
                            className="react-select"
                            classNamePrefix="select"
                            options={[
                              {
                                label: "Chota topup (expires eod)",
                                value:
                                  CREATE_DAILY_TALKTIME_TYPES.DAILY_TALKTIME_CHOTA_TOP_UP,
                              },
                              {
                                label: "Recurring topup",
                                value:
                                  CREATE_DAILY_TALKTIME_TYPES.DAILY_TALKTIME_RECURRING_TOP_UP,
                              },
                            ]}
                            value={topupType}
                            placeholder="Choose Topup Type"
                            onChange={(topupType) => {
                              setTopupType(topupType);
                            }}
                          />
                        </div>
                        <FormGroup>
                          <Label className="form-label" for="p-id">
                            Outgoing Daily Pulse Minutes to be Added (Minutes)
                          </Label>
                          <Input
                            autoFocus
                            type="number"
                            value={talktime}
                            placeholder="Minutes"
                            min={1}
                            max={10000}
                            step={1}
                            id="p-id"
                            name="p-id"
                            onChange={(e) => {
                              let value = e?.target?.value;
                              value = Math.ceil(value / 1) * 1;
                              setTalkTime(parseInt(value));
                            }}
                          />
                        </FormGroup>
                        {topupType.value !==
                        CREATE_DAILY_TALKTIME_TYPES.DAILY_TALKTIME_CHOTA_TOP_UP ? (
                          <>
                            <FormGroup>
                              <Label className="form-label" for="p-id">
                                Select Start date (Optional)
                              </Label>
                              <Flatpickr
                                value={startDate || new Date()}
                                onChange={(date) => setPicker(date)}
                                className="form-control invoice-edit-input date-picker"
                              />
                            </FormGroup>
                            <FormGroup aria-required>
                              <Label className="form-label" for="ed-id">
                                Select End date
                              </Label>
                              <Flatpickr
                                value={endDate || new Date()}
                                onChange={(date) => setPickerEndDate(date)}
                                className="form-control invoice-edit-input date-picker"
                              />
                            </FormGroup>
                          </>
                        ) : null}
                        <div className="tw-pb-4">
                          <Select
                            theme={selectThemeColors}
                            isClearable={false}
                            className="react-select"
                            classNamePrefix="select"
                            options={[
                              {
                                label: "Razorpay",
                                value: "RAZORPAY",
                              },
                              {
                                label: "Bank Transfer",
                                value: "BANK_TRANSFER",
                              },
                            ]}
                            value={paymentType}
                            placeholder="Choose Payment Type"
                            onChange={(paymentType) => {
                              setPaymentType(paymentType);
                            }}
                          />
                        </div>
                        {paymentType?.value === "BANK_TRANSFER" ? (
                          <div className="tw-pb-4">
                            <Input
                              autoFocus
                              type="number"
                              value={amount}
                              placeholder="Enter rupees"
                              id="p-amount-id"
                              name="p-amount-name"
                              onChange={(e) => {
                                setAmount(e?.target?.value || "");
                              }}
                            />
                          </div>
                        ) : null}
                        <div
                          style={{
                            fontSize: "14px",
                            lineHeight: "18px",
                            fontWeight: "600",
                            marginBottom: "1rem",
                          }}
                        >
                          {paymentType?.value === "BANK_TRANSFER" ? (
                            <span>Price : Rs. {amount}</span>
                          ) : null}
                        </div>
                        {paymentType?.value === "BANK_TRANSFER" ? (
                          <div
                            style={{
                              fontSize: "14px",
                              lineHeight: "18px",
                              marginBottom: "1rem",
                              color: "rgba(0,0,0,0.7)",
                            }}
                          >
                            Estimated Price :
                            <span>
                              {" "}
                              Rs.{" "}
                              {(talktime / 60) *
                                15 *
                                Math.ceil(
                                  moment(endDate).diff(
                                    moment(startDate),
                                    "day",
                                    true
                                  )
                                )}
                            </span>
                          </div>
                        ) : null}

                        <FormGroup>
                          <Input
                            autoFocus
                            type="text"
                            value={paymentId}
                            placeholder="Enter Payment Id"
                            id="p-id"
                            name="p-id"
                            onChange={handlePaymentIdChange}
                          />
                          <Label className="form-label" for="p-id">
                            Enter Payment Id (Razorpay/Bank Transfer)
                          </Label>
                        </FormGroup>
                        <FormGroup>
                          <Label className="form-label">GST</Label>
                          <Input
                            value={extraFields.gst || ""}
                            placeholder="Enter GST"
                            id="p-topup-gst-id"
                            name="p-topup-gst-name"
                            onChange={(e) => {
                              setExtraFields({
                                ...extraFields,
                                gst: e?.target?.value || "",
                              });
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label className="form-label">Description</Label>
                          <Input
                            type="textarea"
                            value={extraFields.description}
                            placeholder="Enter Detailed Description"
                            id="p-topup-description-id"
                            name="p-topup-description-name"
                            onChange={(e) => {
                              setExtraFields({
                                ...extraFields,
                                description: e?.target?.value || "",
                              });
                            }}
                          />
                        </FormGroup>
                      </Form>
                    </>
                  </TabPane>
                </TabContent>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={handleOnDismiss}>
                  DISMISS
                </Button>
                <Button color="primary" onClick={handleOnProceed}>
                  PROCEED
                </Button>
              </ModalFooter>
            </>
          )}
        </Modal>
      </div>
    </div>
  );
};

const CreateSubscription = ({ team, subscriptions }) => {
  const currentSubscription = useMemo(() => {
    return subscriptions?.find((s) => s.status === "active");
  }, [subscriptions]);
  // ** Store Vars
  const dispatch = useDispatch();
  const [shouldShowCreateSubModal, setShowCreateSubModal] = useState(false);
  const teamKyc = useSelector((state) => state.teams.kyc);
  const { kyc_template, business_kyc } = teamKyc || {};

  const handleOnClickCreateSub = () => {
    setShowCreateSubModal(true);
    dispatch(getPlans());
    dispatch(getKyc(team.id));
  };

  const onCreateSubInputComplete = (data) => {
    handleCreateSubscription(team, data);
    setShowCreateSubModal(false);
  };

  const onCloseModel = () => {
    setShowCreateSubModal(false);
  };
  const [updateOrg] = useUpdateOrgMutation();
  const [addOrgNote] = useAddOrgNoteMutation();

  const handleCreateSubscription = async (team, subData) => {
    const { plan, variant, order_type, type, payment_type, amount } = subData;
    if (type === "TOUPUP") {
      return MySwal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: `You want to ${subData.talktime} minutes for team id: ${team.id} name: ${team.name} with Payment Type ${payment_type}`,
        confirmButtonText: "YES! CREATE",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-secondary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          toast.info("Creating Topup...Please wait", {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 2000,
          });
          dispatch(createTopup(team, subData, (err) => {}));
        }
      });
    }
    if (type === "TOUPUPV2") {
      return MySwal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: `You want to daily ${subData.talktime} minutes for team id: ${team.id} name: ${team.name} with Payment Type ${payment_type}`,
        confirmButtonText: "YES! CREATE",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-secondary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          toast.info("Creating Topup...Please wait", {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 2000,
          });
          dispatch(
            createDailyTalktimeTopup(team, subData, (err) => {
              if (!err) dispatch(getAllDailyTalktimes(team.id));
            })
          );
        }
      });
    }
    if (!team || !plan || !variant) {
      toast.error("Please select team & plan & variant", {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 2000,
      });
      return;
    }
    if (!team?.superfone_number && type !== "TOUPUP") {
      MySwal.fire({
        title: `Cannot ${
          order_type === "PURCHASE" ? "assign" : "renew"
        } plan to Org as it does not have a superfone number`,
        text: "",
      });
      return;
    }
    if (!team.email && order_type === "PURCHASE") {
      await setOrgEmail({
        org_id: team.id,
        updateApi: async (email) => {
          try {
            if (email) {
              await updateOrg({
                org_id: team.id,
                email,
              });
              await addOrgNote({
                org_id: team.id,
                action_name: "Updated Org",
                comment: `setting email to ${email}`,
              });
              return true;
            } else {
              return false;
            }
          } catch (e) {
            return false;
          }
        },
      });
    }

    MySwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `You want to create ${order_type} subscription for team id: ${
        team.id
      } name: ${team.name} plan: ${plan.name} ${
        variant?.name ? `duration: ${variant.name} ` : ""
      }Rs: ${
        payment_type === "BANK_TRANSFER"
          ? amount
          : (variant.amount / 100).toFixed(2)
      } with Payment Type ${payment_type}`,
      confirmButtonText: "YES! CREATE",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-secondary ml-1",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        toast.info("Creating Subscription...Please wait", {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 2000,
        });
        dispatch(
          createSubscription(team, subData, (err) => {
            if (!err) {
              dispatch(getAllDailyTalktimes(team.id));
              if (
                !(
                  kyc_template?.toLowerCase()?.includes("business") &&
                  business_kyc?.status
                )
              ) {
                // Enable BKYC if not enabled
                toggleBusinessKycBypass(
                  team.id,
                  true,
                  "Enabling BKYC on create manual subscription",
                  true
                ).then((response) => {
                  if (!response) {
                    MySwal.fire({
                      icon: "error",
                      title: "IMPORTANT - Enable BKYC failed",
                      text: `Please turn it ON manually from kyc section`,
                      customClass: { confirmButton: "btn btn-primary" },
                      buttonsStyling: false,
                    });
                  }
                });
              }
            }
          })
        );
      }
    });
  };

  return (
    <Fragment>
      <Button.Ripple color="primary" onClick={handleOnClickCreateSub}>
        Create Plan
      </Button.Ripple>
      {shouldShowCreateSubModal && (
        <CreateSubModal
          visible={shouldShowCreateSubModal}
          onClose={onCloseModel}
          onDismiss={onCloseModel}
          onProceed={onCreateSubInputComplete}
          currentSubscription={currentSubscription}
          team={team}
        />
      )}
    </Fragment>
  );
};

export default CreateSubscription;
