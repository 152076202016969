export const plans = {
  "All-in-one Lite": 19,
  "All-in-one Pro ": 20,
  "All-in-One Lite": 1015,
  "Biz Phone": 1017,
  Standard: 9,
  "WABA only plan": 17,
  "Free Tier Plan (CRM + WABA)": 15,
  "WABA & Biz Phone": 21,
  "WABA + Lite all-in-one": 22,
  "CRM & WABA Plan": 16,
  "CRM Trial": 14,
  "CRM Only Plan": 13,
  "WABA & Biz phone": 10,
  "All-in-One Plan + WABA Advanced": 12,
  "Standard Phone & CRM Legacy Plan": 23,
  "All-in-One Pro + WABA Advanced": 1018,
  "Biz Phone + WABA Advanced": 1019,
  "CRM & Phone Plan": 11,
  "All-in-One Pro": 1016,
  "All-in-One Lite + WABA Advanced": 1020,
  "Unlimited All-in-One Lite": 1021,
  "Unlimited All-in-One Lite + WABA Advanced": 1026,
  "Unlimited Biz Phone": 1023,
  "Unlimited All-in-One Pro": 1022,
  "Demo Plan": 24,
  "Unlimited Biz Phone + WABA Advanced": 1025,
  "Unlimited All-in-One Pro + WABA Advanced": 1024,
};
export const new_plan_ids = [
  plans["Unlimited All-in-One Pro"],
  plans["Unlimited All-in-One Lite"],
  plans["Unlimited Biz Phone"],
  plans["Unlimited All-in-One Pro + WABA Advanced"],
  plans["Unlimited All-in-One Lite + WABA Advanced"],
  plans["Unlimited Biz Phone + WABA Advanced"],
  plans["All-in-One Pro"],
  plans["All-in-One Lite"],
  plans["Biz Phone"],
  plans["All-in-One Pro + WABA Advanced"],
  plans["All-in-One Lite + WABA Advanced"],
  plans["Biz Phone + WABA Advanced"],
];

export const legacy_plan_ids = Object.values(plans).filter(
  (planId) => !new_plan_ids.includes(planId)
);

export const isPlanIdLite = (planId?: number) => {
  return (
    planId === plans["All-in-One Lite"] ||
    planId === plans["All-in-One Lite + WABA Advanced"] ||
    planId === plans["Unlimited All-in-One Lite"] ||
    planId === plans["Unlimited All-in-One Lite + WABA Advanced"] ||
    planId === plans["WABA + Lite all-in-one"] ||
    planId === plans["All-in-one Lite"]
  );
};
